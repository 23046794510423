.App {
  text-align: center;
  font-family: monospace;
}

.Main-Block {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 40.5rem;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}


.App-Header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.About-Body {
  height: 25rem;
}

.Investments-Body {
  height: 25rem;
}

.Investments-Header {
  border-style: dashed;
  border-bottom-width: 1px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-color: #2d3740;
  color: #c9c8c8;
  margin-bottom: 3rem;
}

.Investment {
  border-style: dashed;
  border-bottom-width: 1px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-color: rgb(1, 95, 1);
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.Header {
  border-style: dashed;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-right: none;
  border-left: none;
  margin-top: 1.5rem;
  margin-bottom: 3.5rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.Header-Branding {
  display: flex;
  align-items: center;
}

.Header-Navigation {
  display: flex;
  align-items: center;
}

.Tab {
  cursor: pointer;
  margin-left: 2rem;
}

.Tab:hover:not(.Selected) {
  background: rgb(65, 65, 65);
}

.Selected {
  background: white;
  color: black;
}

.Logo {
  height: 1.75rem;
  cursor: crosshair;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Main-Text {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin: 0;
}

.Body-Text-1 {
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom:1.5rem;
}

.Body-Text-2 {
font-size: 1rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.Foot {
  border-style: dashed;
  border-top-width: 1px;
  border-bottom: none;
  border-right: none;
  border-left: none;
  margin-top: 10.5rem;
}
